/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import HeroReusableV2 from "./HeroReusableV2";
import withStyles from '@mui/styles/withStyles';
// import ModalErrorHandler from "../ModalErrorHandler";
import { Button } from "@mui/material";
const Hero9 = () => {
  const versionNumber = "102";
  const ColorButton = withStyles(() => ({
    root: {
      width: "100%",
      height: "35px",
    },
  }))(Button);
  return (
    <HeroReusableV2
      ColorButton={ColorButton}
      Animation={"HeroAnimation"}
      headerWithDesc={true}
      versionNumber={versionNumber}
    />
  );
};
export default Hero9;
